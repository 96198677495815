<template>
    <v-row>
        <v-col cols="12" lg="8" md="12">
            <div class="mb-2">
                <transition name="grow">
                    <div :class="{ 'grow-expand': selectedLeague != null, 'grow': selectedLeague == null }">
                        <v-footer tile flat class="font-weight-medium shadow-md"
                            :class="$vuetify.breakpoint.smAndUp ? 'pr-2 details_height' : 'details_height_mobile'"
                            :color="$vuetify.theme.dark ? 'dark' : 'white'" :dark="$vuetify.theme.dark" :inset="true">
                            <v-col class="text-left py-0" cols="12">
                                <v-row class="py-0" v-if="this.$vuetify.breakpoint.smAndUp">
                                    <v-col cols="4" :class="this.$vuetify.breakpoint.xs ? 'mt-2' : ''"
                                        class="py-0 d-flex align-center"
                                        :style="this.$vuetify.breakpoint.xs ? 'justify-content: flex-end' : ''">
                                        <div style="position: relative;">
                                            <div>
                                                <h5>
                                                    Search for league
                                                </h5>
                                            </div>
                                        </div>
                                    </v-col>
                                    <v-col v-if="$vuetify.breakpoint.xsOnly">
                                        <div class="flex-grow-1 ml-3">
                                            <v-btn @click="toggleTextField" key="searchButton" color="grey"
                                                class="white--text search-button" small>
                                                <div>Search</div>
                                                <v-icon right dark>mdi-account-search</v-icon>
                                            </v-btn>
                                        </div>
                                        <v-autocomplete v-model="selectedLeague" :loading="loading"
                                            :items="getSearchedLeagues" :search-input.sync="search" item-text="text"
                                            return-object cache-items hide-no-data :style="textFieldStyle" outlined
                                            clearable hide-details ref="searchField" key="searchField" autofocus
                                            class="search-field pa-0" dense filled placeholder="Enter League Name"
                                            append-icon="mdi-close-circle"
                                            @click:append="toggleTextField"></v-autocomplete>
                                    </v-col>
                                    <v-col cols="4" v-else>
                                        <v-autocomplete v-model="selectedLeague" :loading="loading"
                                            :items="getSearchedLeagues" :search-input.sync="search" item-text="text"
                                            return-object cache-items hide-no-data outlined clearable hide-details dense
                                            ref="searchField" key="searchField" autofocus filled
                                            placeholder="Type league name">
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-select id="matchdaySelect" :items="previousGameweeks" label="Matchday"
                                            outlined dense filled hide-details @change="updateGameweek"
                                            v-model="selectedWeek" />
                                    </v-col>
                                </v-row>
                                <!-- <v-row class="py-0" v-if="this.$vuetify.breakpoint.smAndUp">
                                    <v-col cols="12" class="py-0 px-0 d-flex align-center"
                                        style="justify-content: space-evenly;">
                                        <div>
                                            <h6>
                                                {{ leagueTitle
                                                }}
                                            </h6>
                                        </div>
                                        <div style="display: flex;">
                                            <span>
                                                <h6>{{ !this.$vuetify.breakpoint.xs ? 'Position' : 'Pos:' }}
                                                    <span>12</span>
                                                </h6>
                                            </span>
                                            <span class="icon-wrap ml-1">
                                                <v-icon :color="movementColour" class="white--text mr-n1 pb-2">{{
                        positionString
                    }}</v-icon>
                                                <h6 :style="`color: ${movementColour}`">{{
                        1
                            == 0
                            ? '&nbsp;(No Change) ' : 1 }}</h6>
                                            </span>
                                        </div>
                                        <div class="d-flex">
                                            <h6>Points&nbsp;15</h6>
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row v-else class="py-0" no-gutters>
                                    <v-col cols="7">
                                        <v-autocomplete v-model="selectedLeague" :loading="loading"
                                            :items="getSearchedLeagues" :search-input.sync="search" item-text="text"
                                            return-object cache-items hide-no-data outlined clearable hide-details dense
                                            ref="searchField" key="searchField" autofocus filled
                                            placeholder="Type league name" class="mr-1">
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col cols="5">
                                        <v-select id="matchdaySelect" :items="previousGameweeks" label="Matchday"
                                            outlined dense filled hide-details @change="updateGameweek"
                                            v-model="selectedWeek" />
                                    </v-col>
                                </v-row> -->
                            </v-col>
                        </v-footer>
                    </div>
                </transition>
            </div>
            <v-row>
                <v-col cols="12">
                    <chart-card>
                        <template slot="chart">
                            <v-col cols="12">
                                <v-data-table id="leagueTable" :headers="headers" :items="getLeagueState"
                                    item-key="name" sort-by="position" :mobile-breakpoint="expandedTable ? 'xs' : '0'"
                                    class="elevation-1 table-one" :header-class="'table-headers-word-wrap'"
                                    :class="this.$vuetify.breakpoint.smAndDown ? 'hide-header' : ''" :footer-props="{
                        itemsPerPageText: 'Display'
                    }">
                                    <template v-slot:item.position="{ item }">
                                        <b class="ml-4">{{ item.position == 0 ? '-' : item.position }}</b>
                                    </template>
                                    <template v-slot:item.positionChange="{ item }">
                                        <v-chip :class="changeColor(
                        String(item.positionChange)
                    )
                            " small label class="px-2 secondary"> <v-icon class="white--text">{{
                                                arrowDisplay(String(item.positionChange))}}</v-icon>
                                            {{ positionText(String(item.positionChange)) }}
                                        </v-chip>
                                    </template>
                                    <template v-slot:item.weekPoints="{item}">
                                        <b>{{ item.weekpoints }}</b>
                                    </template>
                                    <template v-slot:item.points="{item}">
                                        <b>{{ item.points }}</b>
                                    </template>
                                    <template v-slot:item.displayName="{item}">
                                        <div class="d-flex align-center">
                                            <p class="ma-0 font-weight-medium">
                                                {{ item.displayName }}
                                            </p>
                                        </div>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </template>
                    </chart-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>
<script>
import ChartCard from '@/components/card/ChartCard'
import ListCardRow from '@/components/card/listCard/ListCardRowSelection'
import ListCardRowResult from '@/components/card/listCard/ListCardRowSelectionResult'
import { mapGetters, mapActions } from 'vuex'
import store from '@/store'
import { gsap } from 'gsap';
import _ from 'lodash';

export default {
    name: 'leagueSearch',
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'League Search',
    },
    components: {
        ChartCard,
        ListCardRow,
        ListCardRowResult,
    },
    beforeRouteEnter(to, from, next) {
        // Check if the user is logged in or has a valid authentication token
        const isLoggedIn = store.getters.loggedInUser;

        // If the route requires authentication and the user is not logged in, redirect to the login page
        if (to.meta.requiresAuth && !isLoggedIn) {
            next('/login'); // Redirect to the login page
        } else {
            next(); // Continue to the requested route
        }
    },
    mounted() {
        this.selectedWeek = this.getCurrentGameweek - 1;
    },
    data() {
        return {
            selections: [],
            tab: null,
            itemsPerPage: 6,
            page: 1,
            selectedWeek: 'Current Standings',
            showTextField: false,
            searchText: '',
            loading: false,
            search: null,
            selectedLeague: null,
            expandedTable: false,
        }
    },
    computed: {
        ...mapGetters(['loggedInUser', 'getCurrentGameweek', 'getLeagueState', 'getSearchedLeagues', 'getCurrentLeague']),
        headers() {
            let headers = [
                { text: this.$vuetify.breakpoint.xsOnly ? 'Pos' : 'Position', value: 'position', sortable: false },
                {
                    text: 'Player',
                    value: 'displayName', sortable: false
                },
                // {text: 'Club', value: 'club'},
                { text: 'Week Points', value: 'weekPoints', align: 'center', sortable: false },
                { text: 'This Week', value: 'positionChange', align: 'center', sortable: false },
                { text: 'Overall Points', value: 'points', align: 'center', sortable: false }
            ]
            if (!this.$vuetify.breakpoint.smAndDown) {
                headers.splice(4, 0, { text: 'Weekly Average', value: 'average', align: 'center', sortable: false });
            }
            return headers;
        },
        previousGameweeks() {
            let highestNumber = this.$store.getters.getCurrentGameweek;
            let totalWeeks = [];
            for (let i = 1; i <= highestNumber - 2; i++) {
                totalWeeks.push(i)
            }
            totalWeeks.push({ text: 'Current Standings', value: this.$store.getters.getCurrentGameweek - 1 });
            return totalWeeks;
        },
        positionString() {
            return this.arrowDisplay(String(1));
        },
        movementColour() {
            if (!this.ifValueExists(1)) { return '' };
            if (1 < 0) {
                return 'red'
            } else if (1 == '0') {
                return 'white'
            } else {
                return 'green'
            }
        },
        textFieldStyle() {
            return {
                width: this.showTextField ? '100%' : '0',
            };
        },
        leagueTitle(){
            return this.getCurrentLeague ? this.getCurrentLeague.text: ''
        }
    },
    watch: {
        getCurrentGameweek(val) {
            if (this.selectedLeague && this.selectedLeague.value && this.$store.getters.getCompetitionId) {
                this.fetchPastSelections({ userId: this.selectedLeague.value, competitionId: this.$store.getters.getCompetitionId });
                this.selectedWeek = val - 1;
            }
        },
        search(val) {
            if (val && val !== this.select) {
                this.querySelections(val);
            }
        },
        selectedLeague(val) {
            //let selectedLeagueId = this.findUser(this.selectedLeague);
            if (val) {
                this.updateLeagueSelection(this.selectedLeague.value);
            } else {
                this.clearPastSelections();
            }
        }
    },
    methods: {
        ...mapActions(["fetchLeagueState", "fetchPastSelections", "fetchGameweekResultForUser", "searchByLeagueName", "clearPastSelections"]),
        arrowDisplay(change) {
            if (!this.ifValueExists(change)) { return '' };
            if (change.indexOf("-") > -1) {
                return 'mdi-triangle-small-down'
            } else if (change == "0") {
                return ''
            } else {
                return 'mdi-triangle-small-up'
            }
        },
        ifValueExists(value) {
            if (value !== null && value !== undefined) {
                return true;
            } else {
                return false;
            }
        },
        changeColor(percent) {
            if (!this.ifValueExists(percent)) { return '' };
            if (percent.indexOf("-") > -1) {
                return 'danger white--text'
            } else if (percent == "0") {
                return 'primary white--text'
            } else {
                return 'success white--text'
            }
        },
        positionText(change) {
            if (!this.ifValueExists(change)) { return '' };
            if (change.indexOf("-") > -1) {
                return change.substring(1);
            } else if (change == '0') {
                return 'No Change';
            } else {
                return change
            }
        },
        updateGameweek(val) {
            if (!this.selectedLeague) return;
            if (val == 'Last Week') {
                this.selectedWeek = this.$store.getters['getCurrentGameweek'] - 1;
                this.fetchGameweekResultForUser({ userId: this.selectedLeague.value, gameweek: this.$store.getters['getCurrentGameweek'], sport: this.$router.currentRoute.meta.sport.toUpperCase() });
            } else {
                this.fetchGameweekResultForUser({ userId: this.selectedLeague.value, gameweek: val, sport: this.$router.currentRoute.meta.sport.toUpperCase() });
            }
        },
        toggleTextField() {
            this.showTextField = !this.showTextField;
            if (!this.showTextField) {
                this.selectedLeague = null;
            }
        },
        enterAnimation(el, done) {
            gsap.fromTo(el, { opacity: 0, width: 0 }, { opacity: 1, width: '100%', duration: 1, onComplete: done });
        },
        leaveAnimation(el, done) {
            gsap.to(el, { opacity: 0, width: 0, duration: 1, onComplete: done });
        },
        async querySelections(v) {
            if (v.length > 2) {
                this.loading = true
                let response = await this.searchByLeagueName(v);
                this.loading = false;
                this.items = response.data;
            }
        },
        updateLeagueSelection(leagueId) {
            this.fetchLeagueState(leagueId);
        },
        formatNumber(num) {
            return Number.isInteger(num) ? num : num.toFixed(2);
        }
    }
}
</script>

<style scoped>
.fixture_col {
    display: flex;
    justify-content: center;
    align-items: center;
}

.fixture {
    scroll-margin-top: 20vh;
}

.icon-wrap {
    align-content: center;
    display: flex;
    flex-wrap: wrap;
}

.search-button {
    z-index: 1;
}

.search-field {
    position: absolute;
    right: 0;
    /* Align to the right edge of the parent div */
    top: -20%;
    /* Align to the top of the parent div */
    width: 0;
    /* Start with a width of 0 */
    transition: width 0.5s ease;
    /* Smooth transition for width */
    overflow: hidden;
    /* Hide the overflow when the field is collapsed */
    background-color: grey;
    z-index: 2;
}

.table-headers-word-wrap th {
    white-space: normal;
}

.img_border {
    border-style: solid;
    border-width: 2px;
}

/* .details_height {
    max-height: 200px;
    overflow: hidden;
} */

/* .details_height_mobile {
    max-height: 240px;
    overflow: hidden;
} */

.grow {
    max-height: 70px;
    transition: max-height 0.5s ease-out;
    overflow: hidden;
    /* Ensure content doesn't overflow while transitioning */
}

.grow-enter-active,
.grow-leave-active {
    transition: max-height 0.5s ease-out;
}

.grow-enter,
.grow-leave-to

/* .grow-leave-active in <2.1.8 */
    {
    max-height: 0;
}

.grow-expand {
    transition: max-height 0.5s ease-in;
    max-height: 500px;
    /* Or any max height you expect the element to grow to */
}
</style>